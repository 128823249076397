import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../logo';
import background from '../../assets/images/background.jpg';

// ----------------------------------------------------------------------

export default function Footer() {
  return (
    <footer
      className="footer mt-568:h-380 mt-812:h-60 h-300 bg-cover bg-bottom bg-no-repeat text-gray-300"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="absolute top-0 left-0 w-full h-full z-10 px-10">
        <div className="flex mt-568:flex-row flex-col items-center justify-between mt-10">
          <p>&#169; 2023: We do not store any files on our server and only linked to the media hosted on 3rd party services. If you are a legal owner of any content and want it removed from our site, please contact us at <a href="mailto:support@yourmovies.club">support@yourmovies.club</a> </p>
        </div>
      </div>
    </footer>
  );
};
